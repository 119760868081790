// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - global search reducer
/*
   Modification: Action for global search
   By: RAJKUMAR PRADHAN
   Date: 07-08-2023
*/
export const SEARCH_VALUE = 'SEARCH_VALUE';
export const setSearchValue = (value) => ({
  type: 'SEARCH_VALUE',
  payload: value
});

/*
  modified : Ashutosh G
  modified : 23/08/2023
  modification : PERMISSION_VALUE action added
*/
export const PERMISSION_VALUE = 'PERMISSION_VALUE';
