import CryptoJS from 'crypto-js';
// import { Buffer } from 'buffer';
import crc32 from 'crc32';
// import Base64 from 'crypto-js/enc-base64';
// import { enc, AES } from 'crypto-js';

const getChecksum = (inputData) => {
  let crcKey = 'L2nGKqmJc0wdsfyU/wEx6g==';
  const str = inputData + crcKey;
  // console.log(inputData,"inputDatainputData");
  const checksum = crc32(str).toString(16);
  return parseInt(checksum, 16);
};

export function getEncryptedData(inputData) {
  // following keys will be saved in environment variables
  let key = 'RoyUrOCD44KwPKyd84wYBg==';
  let iv = 'mTfFcmTLjlZfS0DqV4QnJg==';

  let encryptionKey = CryptoJS.enc.Base64.parse(key);
  let initializationVector = CryptoJS.enc.Base64.parse(iv);
  let encrypted = CryptoJS.AES.encrypt(inputData, encryptionKey, {
    iv: initializationVector
  }).toString();

  let checksum = getChecksum(encrypted);
  return { data: encrypted, checksum };
}

export function getDecryptedData(inputData) {
  let checksum = getChecksum(inputData.data);
  let data;
  if (checksum === inputData.checksum) {
    let key = 'cHpGD1Iaho4l5WGkN6CdmA==';
    let iv = '5hyBGJEGwqCIILtS79NdVw==';

    let encryptionKey = CryptoJS.enc.Base64.parse(key);
    let initializationVector = CryptoJS.enc.Base64.parse(iv);
    const decryptedBytes = CryptoJS.AES.decrypt(inputData.data, encryptionKey, { iv: initializationVector });
    data = decryptedBytes.toString(CryptoJS.enc.Utf8);
  }
  return JSON.parse(data);
}

/*export function getDecryptedDataHere (inputData) {
  let checksum = getChecksum(inputData.data);
  let data;
  if (checksum === inputData.checksum) {
    let key = 'RoyUrOCD44KwPKyd84wYBg==';
    let iv  = 'mTfFcmTLjlZfS0DqV4QnJg==';

    let encryptionKey = Buffer.alloc(32);
    encryptionKey.write(key);
    let initializationVector = Buffer.alloc(16);
    initializationVector.write(iv);
    let decipher = crypto.createDecipheriv( "AES-256-CBC", encryptionKey, initializationVector );
    data = (
      decipher.update( inputData.data, "base64", "utf8" ) +
        decipher.final( "utf8" )
    );
  }
  return JSON.parse(data);
}*/
