/*
   Modification: Global search reducer for keeping search text
   value.
   By: RAJKUMAR PRADHAN
   Date: 07-08-2023
*/
import { SEARCH_VALUE } from './actions';

export const initialState = {
  searchText: null
};

// ==============================|| GLOBAL SEARCH REDUCER ||============================== //

const globalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VALUE:
      return {
        ...state,
        searchText: action.payload
      };
    default:
      return state;
  }
};

export default globalSearchReducer;
