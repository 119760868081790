const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  /*localHost api url*/
  API_URL: 'http://localhost:9000',

  basename: '/',
  defaultPath: '/login',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 5
};

export default config;
