const ContactSearchSuggestion = (props) => {
  return (
    <span className={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg
        onClick={(e) => props.action && props.action(e)}
        className={`${props.classes || ''} ${props.identityClass || ''}`}
        width={props.width || '20'}
        height={props.height || '20'}
        viewBox={props.viewBox || '0 0 20 20'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className={props.identityClass || ''} cx="10" cy="10" r="9.5" stroke={props.stroke || '#404040'} />
        <path
          className={props.identityClassName || ''}
          d="M13.9144 15.1003V13.9696C13.9144 13.3699 13.6762 12.7948 13.2521 12.3707C12.8281 11.9466 12.2529 11.7084 11.6532 11.7084H8.26127C7.66154 11.7084 7.08638 11.9466 6.66231 12.3707C6.23824 12.7948 6 13.3699 6 13.9696V15.1003"
          stroke={props.stroke || 'black'}
          strokeOpacity="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClassName || ''}
          d="M9.76908 9.44636C11.0179 9.44636 12.0303 8.43396 12.0303 7.1851C12.0303 5.93623 11.0179 4.92383 9.76908 4.92383C8.52022 4.92383 7.50781 5.93623 7.50781 7.1851C7.50781 8.43396 8.52022 9.44636 9.76908 9.44636Z"
          stroke={props.stroke || 'black'}
          strokeOpacity="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default ContactSearchSuggestion;
