import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
  Typography,
  Stack,
  Paper,
  CircularProgress
} from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import {
  // IconAdjustmentsHorizontal,
  IconSearch,
  IconX
} from '@tabler/icons';
import { Search, Close } from '@mui/icons-material';
import { shouldForwardProp } from '@mui/system';

import {
  ContactSearchSuggestion,
  TaskSearchSuggestion,
  InteractionSearchSuggestion,
  CaseSearchSuggestion
} from '../../../../ui-component/svgs';
import { isEmptyObject, getTextWithHighlightMatching } from '../../../../lib/utils';
import { setItem, getItem } from '../../../../lib/myStore';

import { useDispatch } from 'react-redux';
import { setSearchValue } from '../../../../store/actions';
import { callAPI, reportException, generateCancelTokenSource } from '../../../../lib/transceiver';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px'
  }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('lg')]: {
    width: 250
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 4,
    background: '#fff'
  }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light
  }
}));

let cancelTokenSource;

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({
  value,
  isLoading,
  searchInputRef,
  routeToSearchPage,
  setIsSearchInputFocussed,
  setSearchTextValue,
  routeToSearchPageOnEnter,
  popupState
}) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      inputRef={searchInputRef}
      value={value}
      onFocus={() => setIsSearchInputFocussed(true)}
      onBlur={() => {
        setTimeout(() => {
          setIsSearchInputFocussed(false);
        }, 100);
      }}
      onInput={setSearchTextValue}
      onKeyPress={routeToSearchPageOnEnter}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => routeToSearchPage(value)}>
            <HeaderAvatarStyle variant="rounded">
              {/*<IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />*/}
              {isLoading ? <CircularProgress size="1.3rem" /> : <Search stroke={1.5} size="1.3rem" />}
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => clearSearchTextValue()}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  '&:hover': {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light
                  }
                }}
                {...bindToggle(popupState)}
              >
                <IconX stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ 'aria-label': 'weight' }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsSearchInputFocussed: PropTypes.func,
  setSearchTextValue: PropTypes.func,
  routeToSearchPageOnEnter: PropTypes.func,
  routeToSearchPage: PropTypes.func,
  // popupState: PopupState,
  popupState: PropTypes.object,
  searchInputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

// ==============================|| SEARCH INPUT ||============================== //

const SuggestionList = (props) => {
  const {
    isMobileView,
    isSearchInputFocussed,
    isLoading,
    isSuggestionListShown,
    searchText,
    searchSuggestionList,
    routeToSearchPage,
    getRowButtonIcon
  } = props;

  const responsiveStyle = !isMobileView
    ? {
        top: 51,
        pl: 2
      }
    : {
        top: 90,
        pl: 2,
        pr: 2
      };

  return (
    <>
      {isSearchInputFocussed && !isLoading && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            ...responsiveStyle
          }}
        >
          {searchText && (
            <Paper elevation={4} sx={{ pt: 2, pb: 2 }}>
              {isSuggestionListShown && searchSuggestionList.length > 0 && (
                <Stack direction="column" alignItems="center">
                  {searchSuggestionList.map((item, index) => (
                    <Paper
                      key={[item, index]}
                      onClick={() => routeToSearchPage(item.text)}
                      className="bg-EEE-hov"
                      sx={{ width: '100%', pl: 2, pr: 2, pt: 1, pb: 1, display: 'flex', cursor: 'pointer' }}
                    >
                      <Typography className="flex-c-m">{getRowButtonIcon(item.typeOfResult)}</Typography>
                      <Typography
                        className="flex-c-m"
                        sx={{ ml: 1 }}
                        dangerouslySetInnerHTML={getTextWithHighlightMatching(item.text, searchText)}
                      />
                    </Paper>
                  ))}
                </Stack>
              )}

              {searchSuggestionList.length === 0 && searchText && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'medium',
                    textAlign: 'center'
                  }}
                >
                  No Records
                </Typography>
              )}
            </Paper>
          )}
        </Box>
      )}
    </>
  );
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const fileName = 'searchSection';
  const pageName = 'header';

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let localStorageSearchText = getItem('searchText');
  let searchInputRegex = /[^[\w\-\s]+$]/;

  const searchInputRef = useRef();
  const [isSearchInputFocussed, setIsSearchInputFocussed] = useState(false);

  const [searchText, setSearchText] = useState(!isEmptyObject(localStorageSearchText) ? localStorageSearchText : '');
  const [isLoading, setIsLoading] = useState(true);

  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const [isSuggestionListShown, setIsSuggestionListShown] = useState(false);

  useEffect(() => {
    window.onkeydown = function (event) {
      checkIfSearchKeyComboPressed(event);
      checkIfSearchCloseKeyPressed(event);
    };
  }, [checkIfSearchKeyComboPressed, checkIfSearchCloseKeyPressed]);

  useEffect(() => {
    (async function () {
      cancelTokenSource = generateCancelTokenSource(cancelTokenSource);
      setIsLoading(true);
      if (searchText) {
        await getSearchSuggestions();
      } else {
        setIsSuggestionListShown(false);
      }
      setIsLoading(false);
    })();
  }, [searchText]);

  // Functions
  function checkIfSearchKeyComboPressed(e) {
    let evtobj = window.event ? event : e;
    // combination of ctrl + K
    if (evtobj.keyCode == 75 && evtobj.ctrlKey) {
      e.preventDefault();
      handleOpenSearchSuggestionModal();
    }
  }
  function checkIfSearchCloseKeyPressed(e) {
    let evtobj = window.event ? event : e;
    if (evtobj.keyCode == 27) {
      handleCloseSearchSuggestionModal();
    }
  }

  // for modal handling
  const handleOpenSearchSuggestionModal = () => {
    searchInputRef.current.focus();
  };
  const handleCloseSearchSuggestionModal = () => {
    searchInputRef.current.blur();
  };

  // for value present inside the input box
  const setSearchTextValue = (e) => {
    // Checking the data for a perticular format
    if (!searchInputRegex.test(e.target.value)) {
      setIsLoading(true);
      setSearchText(e.target.value);
      setItem('searchText', e.target.value);
    }
  };
  const clearSearchTextValue = () => {
    // Checking the data for a perticular format
    setIsLoading(true);
    setSearchText('');
    setItem('searchText', '');
    handleOpenSearchSuggestionModal();
  };

  // to find out if "Enter" key has been pressed
  const routeToSearchPageOnEnter = (e) => {
    if (e.key == 'Enter') {
      routeToSearchPage(searchText);
    }
  };

  // It will redirect you to "Search page" as soon as you hit enter
  const routeToSearchPage = (text) => {
    if (text) {
      setItem('searchText', text);
      setSearchText(text);
      // Sending value to the redux
      dispatch(setSearchValue(text));
      navigate('/search');
      handleCloseSearchSuggestionModal();
    }
  };

  // function to find the appropriate icons for the results
  const getRowButtonIcon = (typeOfResult) => {
    if (['Contact', 'User'].includes(typeOfResult)) {
      return <ContactSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Task') {
      return <TaskSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Interaction') {
      return <InteractionSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Case') {
      return <CaseSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Courier') {
      return <Typography title={typeOfResult}>C</Typography>;
    } else if (typeOfResult === 'Visit') {
      return <Typography title={typeOfResult}>V</Typography>;
    }
  };

  const getSearchSuggestions = async () => {
    try {
      let response = await callAPI(`get`, `/v1/globalSearchSuggestions/${encodeURIComponent(searchText)}`, null, { cancelTokenSource });
      if (response && response.data) {
        setSearchSuggestionList(response.data);
        setIsSuggestionListShown(true);
      } else {
        setSearchSuggestionList([]);
        setIsSuggestionListShown(false);
      }
    } catch (HTTPException) {
      setSearchSuggestionList([]);
      setIsSuggestionListShown(false);
      reportException({
        HTTPException,
        fileName,
        pageName,
        functionName: 'getSearchSuggestions'
      });
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper" sx={{ position: 'relative' }}>
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                      <Card
                        sx={{
                          background: '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none'
                          }
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch
                                value={searchText}
                                searchInputRef={searchInputRef}
                                isLoading={isLoading}
                                routeToSearchPage={routeToSearchPage}
                                setIsSearchInputFocussed={setIsSearchInputFocussed}
                                setSearchTextValue={setSearchTextValue}
                                routeToSearchPageOnEnter={routeToSearchPageOnEnter}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>

              <SuggestionList
                isMobileView
                isSearchInputFocussed={isSearchInputFocussed}
                isLoading={isLoading}
                isSuggestionListShown={isSuggestionListShown}
                searchText={searchText}
                searchSuggestionList={searchSuggestionList}
                routeToSearchPage={routeToSearchPage}
                getRowButtonIcon={getRowButtonIcon}
              />
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' }, position: 'relative' }}>
        <OutlineInputStyle
          id="input-search-header"
          inputRef={searchInputRef}
          value={searchText}
          onFocus={() => setIsSearchInputFocussed(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsSearchInputFocussed(false);
            }, 100);
          }}
          onInput={setSearchTextValue}
          onKeyPress={routeToSearchPageOnEnter}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          endAdornment={
            searchText && (
              <InputAdornment position="end">
                <ButtonBase sx={{ borderRadius: '12px' }} onClick={() => !isLoading && clearSearchTextValue()}>
                  <HeaderAvatarStyle variant="rounded">
                    {/*<IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />*/}
                    {
                      isLoading ? <CircularProgress size="1.3rem" /> : <Close stroke={0.5} size="1.3rem" />
                      /*<Search stroke={0.5} size="1.3rem" />*/
                    }
                  </HeaderAvatarStyle>
                </ButtonBase>
              </InputAdornment>
            )
          }
          aria-describedby="search-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
        />
        <SuggestionList
          isSearchInputFocussed={isSearchInputFocussed}
          isLoading={isLoading}
          isSuggestionListShown={isSuggestionListShown}
          searchText={searchText}
          searchSuggestionList={searchSuggestionList}
          routeToSearchPage={routeToSearchPage}
          getRowButtonIcon={getRowButtonIcon}
        />
      </Box>
    </>
  );
};

export default SearchSection;
