import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Box } from '@mui/material';

import { IconArrowBackUp } from '@tabler/icons';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
  '& .MuiCardHeader-title': {
    display: 'flex',
    flexDirection: 'row'
  }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      isCustomPage /** Prop Represents if on creation or updation, instead of generic view of heading, create button and pagination table with popover of createEditModal, a full page with custom children are needed. */,
      backAction /** Prop - Closes this component and shows its parent page again. */,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 25,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          ...sx
        }}
      >
        {/** card header and action (if isCustomPage is true, then header won't be shown) */}
        {title && !isCustomPage && (
          <CardHeader
            sx={headerSX}
            title={
              <>
                {backAction && (
                  <Box sx={{ cursor: 'pointer', display: 'flex', mr: 1 }}>
                    <IconArrowBackUp onClick={backAction} />
                  </Box>
                )}
                {darkTitle ? <Typography variant="h3">{title}</Typography> : title}
              </>
            }
            action={secondary}
          />
        )}

        {/** content & header divider */}
        {title && <Divider />}

        {/** card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
