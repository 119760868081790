/**
 * @file Main file to declare routes and their corresponding route component.
 * @author Devang <devang@technative.in>
 */

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const ProjectDetail = Loadable(lazy(() => import('views/projectDetail')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const EntityActionPage = Loadable(lazy(() => import('views/entityActionPage')));
/*const AdminWorkspace = Loadable(lazy(() => import('views/adminWorkspace')));
const Request = Loadable(lazy(() => import('views/request')));
const Catalogue = Loadable(lazy(() => import('views/catalogue')));
const Vendor = Loadable(lazy(() => import('views/vendor')));
const Facility = Loadable(lazy(() => import('views/facility')));
const ProcessMapping = Loadable(lazy(() => import('views/processMapping')));
const FacilityRoom = Loadable(lazy(() => import('views/facilityRoom')));*/

const EntityFormMapping = Loadable(lazy(() => import('views/entityFormMapping')));
const EntityBulkUploadPage = Loadable(lazy(() => import('views/entityBulkUploadPage')));

const Search = Loadable(lazy(() => import('views/pages/search')));

// const Acl = Loadable(lazy(() => import('views/acl')));
/*const Dealerships = Loadable(lazy(() => import('views/organization/dealership')));
const Branches = Loadable(lazy(() => import('views/organization/branches')));
const Departments = Loadable(lazy(() => import('views/organization/departments')));
const Roles = Loadable(lazy(() => import('views/organization/roles')));
const Users = Loadable(lazy(() => import('views/organization/users')));*/

const Page404 = Loadable(lazy(() => import('views/pages/error-pages/Page404')));
const Page403 = Loadable(lazy(() => import('views/pages/error-pages/Page403')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'home',
      element: <Dashboard />
    },
    {
      path: 'projectDetail/:projectID',
      element: <ProjectDetail />
    },
    {
      path: '/entity/:entityName',
      element: <EntityActionPage />
    },
    /*{
      path: 'adminWorkspace',
      element: <AdminWorkspace />
    },
    {
      path: 'request',
      element: <Request />
    },
    {
      path: 'catalogue',
      element: <Catalogue />
    },
    {
      path: 'thirdParty',
      element: <Vendor />
    },
    {
      path: 'facility',
      element: <Facility />
    },
    {
      path: 'facilityRoom',
      element: <FacilityRoom />
    },*/
    /*{
      path: 'processMapping',
      element: <ProcessMapping />
    },*/
    {
      path: 'entityFormMapping',
      element: <EntityFormMapping />
    },
    /*
      Modification: T1617 - Commented out acl page
      By: Devang
      Date: 15/03/2024
    */
    /*{
      path: 'acl',
      element: <Acl />
    },*/
    {
      path: 'bulkUpload/:entityName',
      element: <EntityBulkUploadPage />
    },
    /*{
      path: 'organization',
      children: [
        {
          path: 'dealerships',
          element: <Dealerships />
        },
        {
          path: 'branches',
          element: <Branches />
        },
        {
          path: 'departments',
          element: <Departments />
        },
        {
          path: 'roles',
          element: <Roles />
        },
        {
          path: 'users',
          element: <Users />
        }
      ]
    },*/
    {
      path: 'search',
      element: <Search />
    },
    {
      path: 'page404',
      element: <Page404 />
    },
    {
      path: 'page403',
      element: <Page403 />
    },
    {
      path: '*',
      element: <Page404 />
    }
  ]
};

export default MainRoutes;
