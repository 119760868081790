import main from './main';
import admin from './admin';
// import organization from './organization';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    main,
    admin
    // organization
  ]
};

export default menuItems;
