/**
 * @file Manages the basic functions and constants to be utilized in the project
 * @author Devang <devang@technative.in>
 */

import axios from 'axios';
import CONSTANTS from './constants';
import { getItem } from './myStore';

export function removeFalseKeyValuePairs(obj) {
  Object.keys(obj).filter((key) => {
    if (Array.isArray(obj[key]) && !obj[key].length) {
      delete obj[key];
    } else if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
}

export function formatDateTimeRelative(date) {
  let dateVal = getMomentCalendarTimeLabel(date, true);
  if (dateVal.includes('/')) {
    let getDateYear = dateVal.split('/') && dateVal.split('/')[2];
    let currentYear = moment().format('YYYY');
    if (getDateYear === currentYear) dateVal = getMomentCalendarTimeLabel(date, false);
  }
  return dateVal;
}

export function getDateColor(date) {
  if (date && date.toLowerCase() === 'today') return '#6bc950';
  return '';
}

export function isValidDate(val) {
  var timestamp = Date.parse(val);

  if (isNaN(timestamp) == false) {
    return true;
  }
  return false;
}

export function getTextWithHighlightMatching(text, searchText, highlightedStyle = {}) {
  const { textColor = '#000', bgColor = '#fbee32' } = highlightedStyle;

  // Regex search
  let searchTextInnerRegex = ``;
  // getting rid of the spaces and converting it into array for regex search
  searchText
    .split(' ')
    .filter((text) => text)
    .map((text) => {
      searchTextInnerRegex += `(?=.*${text})`;
    });
  let searchTextRegex = new RegExp(searchTextInnerRegex, 'i');

  let highlightedText = text;
  if (text && searchText) {
    highlightedText =
      text.replace(searchTextRegex, (match) => `<span style="color: ${textColor}; background: ${bgColor}">${match}`) + '</span>';
  }
  return { __html: highlightedText };
}

export function formatDateTime(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
  }
  return formattedDate;
}
export function formatCurrency(amount) {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  };
  let formattedAmount = '';
  if (amount) {
    formattedAmount = new Intl.NumberFormat('en-US', options).format(amount);
  } else {
    //Bug 863
    // Nothing was returned if the amount was $0
    //Fixed by Rutuja
    //Fixed on 1/7/2021
    formattedAmount = new Intl.NumberFormat('en-US', options).format(0);
  }
  return formattedAmount;
}

export function formatDateForHtmlForm(date) {
  if (date === null) {
    return '';
  } else {
    const DATE = new Date(date);
    let DD = DATE.getDate();
    let MM = DATE.getMonth() + 1;
    const YYYY = DATE.getFullYear();
    if (DD < 10) {
      DD = '0' + DD;
    }
    if (MM < 10) {
      MM = '0' + MM;
    }
    const HtmlFormatDate = `${YYYY}-${MM}-${DD}`;
    return HtmlFormatDate;
  }
}

//toAddresses: email ID or email IDs of a people(s) to whom email has to be sendTo
//description: this is the body description of the email send only body do not add Title or greeting everything else is added in backend
//title is Subject of mail
export function sendNotificationEmail(toAddresses, description, title) {
  let payload = { toAddresses, description, title };
  const request = axios.post(`${CONSTANTS.API_URL}/api/v1/sendEmail`, payload);

  return () =>
    request
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
}

//message is the text which has to be send
// mobile nubers is array of mobileNumbers with their calling code for ex. +919876543210 it can also accept single mobile number
export function sendNotificationSms(message, mobileNumbers) {
  let payload = { message, mobileNumbers };
  const request = axios.post(`${CONSTANTS.API_URL}/api/v1/sendSms`, payload);

  return () =>
    request
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
}

export async function getIpAddress() {
  try {
    const request = await axios.get(`${CONSTANTS.API_URL}/api/v1/getIpAddress`);
    return request.data;
  } catch (error) {
    return error;
  }
}

function convertArrayOfObjectsToCSV(args) {
  function isNumber(n) {
    let t;
    if (n) {
      t = parseFloat(n.toString().replace(/[^a-zA-Z ]/g, ''));
    }
    return isFinite(t) && +t === t;
  }
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;
  data = args.data || null;
  if (data === null || !data.length) {
    return null;
  }
  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';
  keys = Object.keys(data[0]);
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (isNumber(item[key]) === true) {
        result += parseFloat(item[key].toString().replace(/[^a-zA-Z ]/g, ''));
      } else {
        result += '"' + item[key] + '"';
      }
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

export function downloadCSV(csvArray, fileName) {
  let link;
  let csv = convertArrayOfObjectsToCSV({
    data: csvArray
  });
  if (csv === null) return;
  if (!fileName.includes('.csv')) fileName = fileName + '.csv';
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;'
  });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection, Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style = 'visibility:hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function passwordErrorMessageText(data) {
  const regexCaptial = /^(.*?[A-Z])+$/;
  const regexSmall = /^(.*?[a-z])+$/;
  const regexSpCharacter = /^(.*?[#?!@$%^&*-])+$/;
  const regexNumber = /^(.*?[0-9])+$/;
  const regexLength = /^.{6,20}$/;
  const errorMsgForUppercase = ' Uppercase character';
  const errorMsgForLowercase = ' Lowercase character';
  const errorMsgForNumber = ' Number';
  const errorMsgForSpCharacter = ' Symbol';
  const errorMsgForLength = ' and password length should be at least 6 characters long';
  let passwordErrorMessage = 'Password must contain atleast one';

  let mapCompleted = false;
  let capitalTested = false;
  let smallTested = false;
  let spCharacterTested = false;
  let numberTested = false;
  let lengthTested = false;
  let string = data.split('');
  passwordErrorMessage = 'Password must contain atleast one';
  if (data.length > 0) {
    string.map((letter, index) => {
      if (letter.match(regexCaptial)) {
        capitalTested = true;
      }
      if (letter.match(regexSmall)) {
        smallTested = true;
      }
      if (letter.match(regexSpCharacter)) {
        spCharacterTested = true;
      }
      if (letter.match(regexNumber)) {
        numberTested = true;
      }
      if (index === string.length - 1) {
        mapCompleted = true;
      }
      return '';
    });
    if (data.match(regexLength)) {
      lengthTested = true;
    }
    if (mapCompleted) {
      if (!capitalTested) {
        passwordErrorMessage =
          !smallTested || !spCharacterTested || !numberTested || !lengthTested
            ? passwordErrorMessage + errorMsgForUppercase + ','
            : passwordErrorMessage + errorMsgForUppercase;
      }
      if (!smallTested) {
        passwordErrorMessage =
          !spCharacterTested || !numberTested || !lengthTested
            ? passwordErrorMessage + errorMsgForLowercase + ','
            : passwordErrorMessage + errorMsgForLowercase;
      }
      if (!spCharacterTested) {
        passwordErrorMessage =
          !numberTested || !lengthTested
            ? passwordErrorMessage + errorMsgForSpCharacter + ','
            : passwordErrorMessage + errorMsgForSpCharacter;
      }
      if (!numberTested) {
        passwordErrorMessage = !lengthTested ? passwordErrorMessage + errorMsgForNumber + ',' : passwordErrorMessage + errorMsgForNumber;
      }
      if (!lengthTested) {
        passwordErrorMessage = passwordErrorMessage + errorMsgForLength;
      }
      return passwordErrorMessage;
    }
  } else {
    passwordErrorMessage = '';
  }
}

export function formatDateTimeUS(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  }
  return formattedDate;
}

export function formatDateTimeUSForPDF(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  let formattedDate = '';
  let formattedDate1 = '';

  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  // console.log(date);
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    formattedDate1 = formattedDate.split('/').join('.');
  }
  return formattedDate1;
}

export function throwError(msg) {
  throw new Error(msg);
}

export function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}';
}

export function getFormattedAmount(amount) {
  if (!isNaN(amount) || amount === 0) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount).split('.')[0];
  }
  return '-';
}

export function inWords(num) {
  let a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '
  ];
  let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = '';
  str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += n[5] != 0 ? (str != '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}

export function getInitials(displayName) {
  if (typeof displayName === 'string') {
    let splitDisplayName = displayName ? displayName.split(' ') : '';
    let firstName = splitDisplayName[0] ? splitDisplayName[0].split('')[0].toUpperCase() : '';
    let lastName = splitDisplayName[1] ? splitDisplayName[1].split('')[0].toUpperCase() : '';
    return firstName + lastName;
  }
}

export function isString(val) {
  return Object.prototype.toString.call(val) === '[object String]';
}

export const typeOfEnquiryMappingClassColor = {
  Hot: 'dark-red',
  New: 'blue',
  Cold: 'blue',
  Warm: 'orange',
  Cancelled: 'dark-red',
  Purchased: 'green',
  Self: 'orange'
};

export function getMomentCalendarTimeLabel(date, isYearNeeded) {
  return moment(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: isYearNeeded ? 'DD/MM/YYYY' : 'DD MMM'
  });
}

export const registrationTypeMappingClassColor = {
  Taxi: 'yellow',
  'Non Taxi': 'light-brown'
};

export const registrationTypeMapping = {
  'Individual (Owned House)': 'Non Taxi',
  'Individual (Rented House)': 'Non Taxi',
  'Corporate (Owned)': 'Non Taxi',
  'Corporate (Rented)': 'Non Taxi',
  CRTM: 'Taxi',
  NRI: 'Non Taxi',
  BH: 'Non Taxi'
};

export const registrationTypeTextMappingClassColor = {
  Taxi: 'text-black',
  'Non Taxi': 'text-white'
};

/**
 * Generate english words out of a camelCase word
 * @param {string} obj - The string (camelcase word) that has a value.
 * @returns {string} an english word
 */
export function camelCaseToNormalCase(str) {
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

/**
 * Make the first letter of the word capitalized.
 *
 * @param {string} obj - The string (english word) that has a value.
 * @returns {string} A String with its first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
  return str && str.length ? str[0].toUpperCase() + str.slice(1) : '';
}

/**
 * Deep copy an object or an array.
 *
 * @param {Object|Array} obj - The object or array to be deep copied.
 * @returns {Object|Array} A deep copy of the input object or array.
 */
export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Recursive function which finds object matching url
 */
export function findObjectsWithURL(data, targetURL) {
  const foundObjects = [];
  for (const obj of data) {
    if (obj.url === targetURL) {
      foundObjects.push(obj);
    }
    if (obj.children) {
      foundObjects.push(...findObjectsWithURL(obj.children, targetURL));
    }
  }
  return foundObjects;
}

/**
 * Get 'userinfo'-item from localStorage.
 *
 * @returns {Object} Information of the logged in user.
 */
export function getUserInfo() {
  return getItem('userInfo');
}

/**
 * Returns error with custom parameter name if parameter is not provided by the user.
 * Function can be used as default parameter of any param which is required and if not given,
 * this function will run and give an error
 *
 * @param {string} [paramName=Parameter] - Name of the parameter that is required.
 * @returns {Error} Error with custom parameter message.
 */
export function isRequired(paramName = 'Parameter') {
  throw new Error(`${paramName} is required`);
}

/**
 * Computes and checks if all the values in 2 array of strings are same.
 *
 * @param {Array} arr1 - First array to compare from.
 * @param {Array} arr2 - Second array to compare from.
 * @returns {Boolean} Representing if both given arrays are same or not.
 * @throws {Error} If either of 2 arrays are not provided in the parameters.
 */
export function areArraysSimilar(arr1 = isRequired('Array 1'), arr2 = isRequired('Array 2')) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays and compare them
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false; // Arrays are different
    }
  }

  return true; // Arrays are similar
}

/**
 * An array of strings valid for usage in MUI alert type.
 * @type {string[]}
 */
export const toasterSeverityEnum = ['error', 'warning', 'info', 'success'];

export function areValuesDifferent(a, b) {
  // Check if both a and b are undefined or empty
  if ((a === undefined || a === '') && (b === undefined || b === '')) {
    return false; // They are considered equal in this case
  }

  // Check if one of them is undefined or empty
  if (a === undefined || a === '' || b === undefined || b === '') {
    return true; // They are considered different
  }

  // Compare the values if both are defined and not empty
  return a !== b;
}
