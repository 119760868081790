const CaseSearchSuggestion = (props) => {
  return (
    <span className={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg
        onClick={(e) => props.action && props.action(e)}
        className={`${props.classes || ''} ${props.identityClass || ''}`}
        width={props.width || '20'}
        height={props.height || '20'}
        viewBox={props.viewBox || '0 0 20 20'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.668 5.83337H3.33464C2.41416 5.83337 1.66797 6.57957 1.66797 7.50004V15.8334C1.66797 16.7538 2.41416 17.5 3.33464 17.5H16.668C17.5884 17.5 18.3346 16.7538 18.3346 15.8334V7.50004C18.3346 6.57957 17.5884 5.83337 16.668 5.83337Z"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3346 17.5V4.16667C13.3346 3.72464 13.159 3.30072 12.8465 2.98816C12.5339 2.67559 12.11 2.5 11.668 2.5H8.33464C7.89261 2.5 7.46869 2.67559 7.15612 2.98816C6.84356 3.30072 6.66797 3.72464 6.66797 4.16667V17.5"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default CaseSearchSuggestion;
