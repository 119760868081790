import history from '../@history';
export class AppStore {
  static get(key) {
    /*
      Modification: Added condition and reload to route to login properly
      By: Devang
      Date: 13/09/2023
    */
    if (!window.localStorage.length && !['/', '/login', '/forgotPassword', '/set-new-password'].includes(window.location.pathname)) {
      history.push(`/login`);
      window.location.reload();
    }
    return JSON.parse(window.localStorage.getItem(key) || '{}');
  }
  static set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  static remove(key) {
    window.localStorage && window.localStorage.removeItem(key);
  }
  static removeAll() {
    window.localStorage && window.localStorage.clear();
  }
}
