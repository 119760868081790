import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import axios from 'axios';
import { getItem, removeAll } from './lib/myStore';

// style + assets
import 'assets/scss/style.scss';
import config from './config';

import { getEncryptedData, getDecryptedData } from './lib/encryption';

// const HTTP_UNAUTHORIZED_CODE = 401;

function processPendingRequests(requestQueue) {
  requestQueue = JSON.parse(requestQueue);
  // requestQueue = JSON.parse(getItem('requestQueue'));
  let promises = [];
  requestQueue.map((req) => {
    let method;
    if (req.method === 'PUT') {
      method = 'put';
    }
    if (req.method === 'POST') {
      method = 'post';
    }
    if (req.method === 'DELETE') {
      method = 'del';
    }
    promises.push(axios[method](req.url, req.body));
    /*requestQueue.splice(index, 1);
    a = requestQueue;
    setItem('requestQueue', JSON.stringify(a));*/
  });
  return Promise.all(promises).then(() => {
    console.log('All offline request queue has been cleared');
    removeItem('requestQueue');
  });
}

/*window.addEventListener('offline', () => {
  // console.log('offline');
  alert('You are working offline now. The data will be synced when the internet is back.');
});*/

window.addEventListener('online', () => {
  console.log('online');
  //When user is online, get requests from queue and call corresponding APIs
  let requestQueue = getItem('requestQueue');
  if (!Object.keys(requestQueue).length) return;
  processPendingRequests(requestQueue);
});

axios.interceptors.request.use(
  (config) => {
    if (config.url.search('amazonaws') === -1 && config.url.search('zoop') === -1) {
      if (config.data) {
        if (!config.headers['is-ignore-encryption']) {
          config['data'] = getEncryptedData(JSON.stringify(config.data));
        }
      }
      const token = getItem('token');
      if (token) {
        config.headers['Authorization'] = token;
      }
      config.headers['Content-Type'] = 'application/json';
    } else {
      delete config.headers.authorization;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.source && response.data.source.data) {
      if (!response.headers['is-ignore-encryption']) {
        let decryptedData = getDecryptedData(response.data.source);
        response['data'] = decryptedData;
      }
    }
    return response;
  },
  (error) => {
    console.error(error, 'Error');
    if (error.response && error.response.status === 401) {
      /*window.alert({
        title: "Session Expired",
        text: "Your session has expired. Would you like to be redirected to the login page?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        closeOnConfirm: false
    }, function(){*/
      removeAll();
      window.location = '/';
      // });
    } else if (error.response && error.response.status === 706) {
      removeAll();
      window.location = '/';
    } else {
      return Promise.reject(error);
    }
  }
);

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
