import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

import { Navigate } from 'react-router-dom';

/**
 * Commented Register Page
 */
/*const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));*/
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const AuthSetNewPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/SetNewPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    /**
     * Default route will always route to /login page on page load
     */
    {
      index: true, // This makes it the default route
      path: '/',
      element: <Navigate to="/login" />
    },
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    /**
     * Commented Register Page
     */
    /*{
      path: '/register',
      element: <AuthRegister3 />
    },*/
    {
      path: '/forgotPassword',
      element: <AuthForgotPassword />
    },
    {
      path: '/set-new-password',
      element: <AuthSetNewPassword />
    }
  ]
};

export default AuthenticationRoutes;
