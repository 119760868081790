// assets
import {
  IconDashboard,
  IconBrandChrome,
  IconUsers,
  IconBuildingSkyscraper,
  IconBuilding,
  IconViewfinder,
  IconAddressBook,
  IconAffiliate,
  IconAssembly,
  IconGitBranch,
  IconApps,
  IconReportMedical,
  IconBrandMatrix,
  IconTransform,
  IconSTurnUp,
  IconSubtask,
  IconBug,
  IconHeartRateMonitor,
  IconGitCommit,
  IconCodeCircle2,
  IconBox,
  IconCubeUnfolded
} from '@tabler/icons';

const icons = {
  IconDashboard,
  IconBrandChrome,
  IconUsers,
  IconBuildingSkyscraper,
  IconBuilding,
  IconViewfinder,
  IconAddressBook,
  IconAffiliate,
  IconAssembly,
  IconGitBranch,
  IconApps,
  IconReportMedical,
  IconBrandMatrix,
  IconTransform,
  IconSTurnUp,
  IconSubtask,
  IconBug,
  IconHeartRateMonitor,
  IconGitCommit,
  IconCodeCircle2,
  IconBox,
  IconCubeUnfolded
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const main = {
  id: 'main',
  title: 'Menu',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/home',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    /*{
      id: 'sample-page',
      title: 'Sample Page',
      type: 'item',
      url: '/sample-page',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },*/
    {
      id: 'entity',
      coreEntityID: 'entity',
      title: 'Entities',
      type: 'collapse',
      icon: icons.IconAffiliate,
      children: [
        {
          id: 'company',
          coreEntityID: 'entity-company',
          title: 'Company',
          type: 'item',
          url: '/entity/company',
          icon: icons.IconBuildingSkyscraper,
          breadcrumbs: false
        },
        {
          id: 'tenant',
          coreEntityID: 'entity-tenant',
          title: 'Tenant',
          type: 'item',
          url: '/entity/tenant',
          icon: icons.IconBuilding, // Change
          breadcrumbs: false
        },
        {
          id: 'client',
          coreEntityID: 'entity-client',
          title: 'Client',
          type: 'item',
          url: '/entity/client',
          icon: icons.IconAddressBook,
          breadcrumbs: false
        },
        {
          id: 'project',
          coreEntityID: 'entity-project',
          title: 'Project',
          type: 'item',
          url: '/entity/project',
          icon: icons.IconAssembly,
          breadcrumbs: false
        },
        {
          id: 'milestone',
          coreEntityID: 'entity-milestone',
          title: 'Milestone',
          type: 'item',
          url: '/entity/milestone',
          icon: icons.IconGitBranch,
          breadcrumbs: false
        },
        {
          id: 'component',
          coreEntityID: 'entity-component',
          title: 'Component',
          type: 'item',
          url: '/entity/component',
          icon: icons.IconApps,
          breadcrumbs: false
        },
        {
          id: 'feature',
          coreEntityID: 'entity-feature',
          title: 'Feature',
          type: 'item',
          url: '/entity/feature',
          icon: icons.IconCubeUnfolded,
          breadcrumbs: false
        },
        {
          id: 'module',
          coreEntityID: 'entity-module',
          title: 'Module',
          type: 'item',
          url: '/entity/module',
          icon: icons.IconBox,
          breadcrumbs: false
        },
        {
          id: 'enhancement',
          coreEntityID: 'entity-enhancement',
          title: 'Enhancement',
          type: 'item',
          url: '/entity/enhancement',
          icon: icons.IconSTurnUp,
          breadcrumbs: false
        },
        {
          id: 'changeFunctionalityRequest',
          coreEntityID: 'entity-changeFunctionalityRequest',
          title: 'Change Functionality Req.',
          type: 'item',
          url: '/entity/changeFunctionalityRequest',
          icon: icons.IconTransform,
          breadcrumbs: false
        },
        {
          id: 'testCase',
          coreEntityID: 'entity-testCase',
          title: 'Test Case',
          type: 'item',
          url: '/entity/testCase',
          icon: icons.IconReportMedical,
          breadcrumbs: false
        },
        {
          id: 'dependencyMatrix',
          coreEntityID: 'entity-dependencyMatrix',
          title: 'Dependency Matrix',
          type: 'item',
          url: '/entity/dependencyMatrix',
          icon: icons.IconBrandMatrix,
          breadcrumbs: false
        },
        {
          id: 'sprint',
          coreEntityID: 'entity-sprint',
          title: 'Sprint',
          type: 'item',
          url: '/entity/sprint',
          icon: icons.IconGitCommit,
          breadcrumbs: false
        },
        {
          id: 'release',
          coreEntityID: 'entity-release',
          title: 'Release',
          type: 'item',
          url: '/entity/release',
          icon: icons.IconCodeCircle2,
          breadcrumbs: false
        },
        {
          id: 'testRun',
          coreEntityID: 'entity-testRun',
          title: 'Test Run',
          type: 'item',
          url: '/entity/testRun',
          icon: icons.IconHeartRateMonitor,
          breadcrumbs: false
        },
        {
          id: 'bugLog',
          coreEntityID: 'entity-bugLog',
          title: 'Bug Log',
          type: 'item',
          url: '/entity/bugLog',
          icon: icons.IconBug,
          breadcrumbs: false
        },
        {
          id: 'task',
          coreEntityID: 'entity-task',
          title: 'Task',
          type: 'item',
          url: '/entity/task',
          icon: icons.IconSubtask,
          breadcrumbs: false
        }
      ]
    }
    /*{
      id: 'organization',
      coreEntityID: 'organization',
      title: 'Organization',
      type: 'collapse',
      icon: icons.IconUsers,
      children: [
        {
          id: 'dealerships',
          coreEntityID: 'organization-dealerships',
          title: 'Dealerships',
          type: 'item',
          url: '/organization/dealerships',
          breadcrumbs: false
        },
        {
          id: 'branches',
          coreEntityID: 'organization-Branches',
          title: 'Branches',
          type: 'item',
          url: '/organization/branches',
          breadcrumbs: false
        },
        {
          id: 'departments',
          coreEntityID: 'organization-departments',
          title: 'Departments',
          type: 'item',
          url: '/organization/departments',
          breadcrumbs: false
        },
        {
          id: 'roles',
          coreEntityID: 'organization-roles',
          title: 'Roles',
          type: 'item',
          url: '/organization/roles',
          breadcrumbs: false
        },
        {
          id: 'users',
          coreEntityID: 'organization-users',
          title: 'Users',
          type: 'item',
          url: '/organization/users',
          breadcrumbs: false
        }
      ]
    }*/
  ]
};

export default main;
