import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet
  // useLocation,
  // useNavigate
} from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import {
  SET_MENU
  // PERMISSION_VALUE
} from 'store/actions';
// import { getItem } from '../../lib/myStore';
// import { callAPI, reportException } from '../../lib/transceiver';
// import { findObjectsWithURL } from '../../lib/utils';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  // let fileName = 'MainLayout-index.js',
  // pageName = 'MainLayout-index.js';
  // const userInfo = getItem('userInfo');
  // const location = useLocation();
  // const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  /*
    modified : Ashutosh G
    modified : 24/07/2023
    modification : getting permission of user on each route switch and redirect to page403 if don't have isRead permission
  */
  /*
    Modification: T1617 - Commented out acl check function call for the user
    By: Devang
    Date: 15/03/2024
  */
  /*useEffect(() => {
    getRulesForLoggedInUser();
  }, [location]);*/

  /*
    SCOPE FOR IMPROVMENT, function can be stored in separate file
  */
  /*async function getRulesForLoggedInUser() {
    try {
      let uuid = [userInfo.uuid];
      let coreEntityObject = findObjectsWithURL(navigation.items, location.pathname);
      let coreEntityID = coreEntityObject && coreEntityObject.length && coreEntityObject[0].coreEntityID;
      let response;
      if (coreEntityID && uuid.length > 0) {
        response = await callAPI(`get`, `/v1/rules`, `ruleFor=${'user'}&coreEntity=${coreEntityID}&uuids=${uuid}`);
        if (response && response.data && response.data.length > 0) {
          dispatch({ type: PERMISSION_VALUE, payload: response.data[0] });
        }
      }
      if (response && response.data && response.data.length && response.data[0].isRead === false) {
        navigate('/Page403');
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        functionName: 'getRulesForLoggedInUser',
        fileName,
        pageName
      });
    }
  }*/

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
      <Customization />
    </Box>
  );
};

export default MainLayout;
