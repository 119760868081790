// material-ui
// import logo from 'assets/images/logo.png';
import BB_logo_text from 'assets/images/bb_logo_text.png';
import { useEffect } from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  useEffect(() => {
    displayResizedImage();
  }, []);

  function displayResizedImage() {
    let maxh = 60,
      maxw = 120,
      imageWidth,
      imageHeight;
    const img = document.createElement('img');
    img.src = BB_logo_text;

    img.onload = async function () {
      imageWidth = this.width;
      imageHeight = this.height;
      // let ratio = maxh/maxw;
      if (this.height > maxh) {
        this.width = Math.round(this.width * (maxh / this.height));
        this.height = maxh;
        imageWidth = this.width;
        imageHeight = this.height;
        if (document.getElementById('referenceImage')) {
          document.getElementById('referenceImage').style.height = maxh + 'px';
          document.getElementById('referenceImage').style.width = this.width + 'px';
          img.style.cssText = 'padding:10px';
        }
        if (this.width > maxw) {
          this.height = Math.round(this.height * (maxw / this.width));
          this.width = maxw;
          imageWidth = this.width;
          imageHeight = this.height;

          if (document.getElementById('referenceImage')) {
            document.getElementById('referenceImage').style.height = imageHeight + 'px';
            document.getElementById('referenceImage').style.width = imageWidth + 'px';
            img.style.cssText = 'padding:0 10px';
          }
        }
      } else if (this.width > maxw) {
        this.height = Math.round(this.height * (maxw / this.width));
        this.width = maxw;
        imageWidth = this.width;
        imageHeight = this.height;
        if (document.getElementById('referenceImage')) {
          document.getElementById('referenceImage').style.height = imageHeight + 'px';
          document.getElementById('referenceImage').style.width = imageWidth + 'px';
          img.style.cssText = 'padding:10px';
        }
      } else if (this.height < maxh) {
        this.height = Math.round(this.height * (maxw / this.width));
        this.width = maxw;
        imageWidth = this.width;
        imageHeight = this.height;
        if (document.getElementById('referenceImage')) {
          document.getElementById('referenceImage').style.height = maxh + 'px';
          document.getElementById('referenceImage').style.width = maxw + 'px';
          img.style.cssText = 'padding:0 10px';
        }
      } else if (document.getElementById('referenceImage')) {
        document.getElementById('referenceImage').style.height = maxh + 'px';
        document.getElementById('referenceImage').style.width = this.width + 'px';
        this.width = imageWidth;
        img.style.cssText = 'padding: 0 10px';
      }
      if (document.getElementById('referenceImage')) {
        document.getElementById('referenceImage').innerHTML = '';
      }
    };
  }

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    /*<img src={logo} alt="Berry" width="100" />*/
    <img id="referenceImage" src={BB_logo_text} alt="Backbone_Logo" style={{ maxHeight: '60px', maxWidth: '120px' }} />
  );
};

export default Logo;
