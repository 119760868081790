const TaskSearchSuggestion = (props) => {
  return (
    <span className={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg
        onClick={(e) => props.action && props.action(e)}
        className={`${props.classes || ''} ${props.identityClass || ''}`}
        width={props.width || '20'}
        height={props.height || '20'}
        viewBox={props.viewBox || '0 0 20 20'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={props.identityClass || ''}
          d="M12.5013 1.66663H7.5013C7.04106 1.66663 6.66797 2.03972 6.66797 2.49996V4.16663C6.66797 4.62686 7.04106 4.99996 7.5013 4.99996H12.5013C12.9615 4.99996 13.3346 4.62686 13.3346 4.16663V2.49996C13.3346 2.03972 12.9615 1.66663 12.5013 1.66663Z"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClass || ''}
          d="M13.332 3.33337H14.9987C15.4407 3.33337 15.8646 3.50897 16.1772 3.82153C16.4898 4.13409 16.6654 4.55801 16.6654 5.00004V16.6667C16.6654 17.1087 16.4898 17.5327 16.1772 17.8452C15.8646 18.1578 15.4407 18.3334 14.9987 18.3334H4.9987C4.55667 18.3334 4.13275 18.1578 3.82019 17.8452C3.50763 17.5327 3.33203 17.1087 3.33203 16.6667V5.00004C3.33203 4.55801 3.50763 4.13409 3.82019 3.82153C4.13275 3.50897 4.55667 3.33337 4.9987 3.33337H6.66536"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClass || ''}
          d="M10 9.16663H13.3333"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClass || ''}
          d="M10 13.3334H13.3333"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClass || ''}
          d="M6.66797 9.16663H6.6763"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={props.identityClass || ''}
          d="M6.66797 13.3334H6.6763"
          stroke={props.stroke || '#404040'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default TaskSearchSuggestion;
