/*
  modified : Ashutosh G
  modified : 23/08/2023
  modification : permissionReducer reducer added
*/

import { PERMISSION_VALUE } from './actions';

export const initialState = {
  isRead: true,
  isCreate: true,
  isEdit: true,
  isDelete: true,
  isExport: true
};

// ==============================|| Permission REDUCER ||============================== //

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_VALUE:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export default permissionReducer;
