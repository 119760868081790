import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import globalSearchReducer from './globalSearchReducer';
import permissionReducer from './permissionReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  globalSearch: globalSearchReducer,
  /*
    modified : Ashutosh G
    modified : 23/08/2023
    modification : permissionReducer reducer registered
  */
  permissionReducer: permissionReducer
});

export default reducer;
