// assets
import {
  // IconRoute,
  IconTopologyBus,
  IconUpload,
  IconBriefcase,
  IconReportMedical,
  // IconGitPullRequest,
  // IconDoor,
  // IconSmartHome,
  // IconBuildingStore,
  // IconFileDots,
  // IconLayoutList,
  // IconUserCircle,
  IconLock
} from '@tabler/icons';

// constant
const icons = {
  // IconRoute,
  IconTopologyBus,
  IconUpload,
  IconBriefcase,
  IconReportMedical,
  // IconGitPullRequest,
  // IconDoor,
  // IconSmartHome,
  // IconBuildingStore,
  // IconFileDots,
  // IconLayoutList,
  // IconUserCircle,
  IconLock
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: 'admin',
  title: 'Admin',
  type: 'group',
  children: [
    /*{
      id: 'admin-workspace',
      title: 'Admin Workspace',
      type: 'item',
      url: '/adminWorkspace',
      icon: icons.IconUserCircle,
      breadcrumbs: false
    },
    {
      id: 'request',
      title: 'Request',
      type: 'item',
      url: '/request',
      icon: icons.IconGitPullRequest,
      breadcrumbs: false
    },
    {
      id: 'admin-workspace-List',
      title: 'Admin Workspace',
      type: 'item',
      url: '/adminWorkspaceList',
      icon: icons.IconLayoutList,
      breadcrumbs: false
    },
    {
      id: 'catalogue',
      title: 'Catalogue ',
      type: 'item',
      url: '/catalogue',
      icon: icons.IconFileDots,
      breadcrumbs: false
    },
    {
      id: 'thrid-party-vendor',
      title: 'Vendors',
      type: 'item',
      url: '/thirdParty',
      icon: icons.IconBuildingStore,
      breadcrumbs: false
    },
    {
      id: 'facility',
      title: 'Facility',
      type: 'item',
      url: '/facility',
      icon: icons.IconSmartHome,
      breadcrumbs: false
    },
    {
      id: 'facility-room',
      title: 'Facility Room',
      type: 'item',
      url: '/facilityRoom',
      icon: icons.IconDoor,
      breadcrumbs: false
    },*/
    /*
      Modification: T1617 - Commented out acl page
      By: Devang
      Date: 15/03/2024
    */
    /*{
      id: 'acl',
      coreEntityID: 'acl',
      title: 'Access Control Configuration',
      type: 'item',
      url: '/acl',
      icon: icons.IconLock,
      breadcrumbs: false
    },*/
    /*{
      id: 'process-mapping',
      title: 'Process Mapping',
      type: 'item',
      url: '/processMapping',
      icon: icons.IconRoute,
      breadcrumbs: false
    },*/
    {
      id: 'entity-form-mapping',
      title: 'Entity Form Mapping',
      type: 'item',
      url: '/entityFormMapping',
      icon: icons.IconTopologyBus,
      breadcrumbs: false
    },
    {
      id: 'bulkUpload',
      coreEntityID: 'bulkUpload',
      title: 'Bulk Upload',
      type: 'collapse',
      icon: icons.IconUpload,
      children: [
        {
          id: 'project',
          coreEntityID: 'bulkUpload-project',
          title: 'Project',
          type: 'item',
          url: '/bulkUpload/project',
          icon: icons.IconBriefcase,
          breadcrumbs: false
        },
        {
          id: 'testCase',
          coreEntityID: 'bulkUpload-testCase',
          title: 'Test Case',
          type: 'item',
          url: '/bulkUpload/testCase',
          icon: icons.IconReportMedical,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default admin;
